import { isActive } from '../_variables';
import $ from 'jquery';

export default class Tab {
  constructor(props) {
    this.elm = document.querySelector(props.target);
  }

  tabContent () {
    const tabs = document.querySelector(".js-content-tab");
    const tabsBtns = tabs.querySelectorAll(".c-list-tab__btn");
    const tabsContents = tabs.querySelectorAll(".c-tab-content__item");

    function displayCurrentTab(current) {
      for (let i = 0; i < tabsContents.length; i++) {
        tabsContents[i].style.display = (current === i) ? "block" : "none";
      }
    }

    displayCurrentTab(0);

    tabsBtns.forEach( eachElement => {
      eachElement.addEventListener("click", event => {

        for (let i = 0; i < tabsBtns.length; i++) {
          tabsBtns[i].classList.remove(isActive);
        };

        if (event.target.className === "c-list-tab__btn") {
          for (let i = 0; i < tabsBtns.length; i++) {
            if (event.target === tabsBtns[i]) {
              tabsBtns[i].classList.add(isActive);
              displayCurrentTab(i);
              break;
            }
          }
        }
      });
    });
  }


  init() {
    if (this.elm) {
      this.tabContent();
    }
  }
}