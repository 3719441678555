import { breakpointMD } from '../_variables';
import $ from 'jquery';
import slick from 'slick-carousel';

export default class SliderSp {
  constructor(props) {
    this.elm = document.querySelector(props.target);
  }


  init() {
    if (this.elm) {
      if (window.innerWidth < breakpointMD) {
        $('.js-slider-sp').slick({
          centerPadding: '30px',
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
          infinite: true,
          arrows: false,
          dots: true,
          centerMode: true,
        });
      }
    }
  }
}