import _ from 'lodash';
import $ from 'jquery';
import {isActive, isShow, breakpointMD} from '../_variables';

export default class Gnavisp {
  constructor(props) {
    this.elm = document.querySelectorAll(props.target);
    this.btnClose = document.querySelectorAll('.js-close-menu');
  };

  init(){
    if(this.elm.length){
      const menuSp = document.querySelector('.c-nav-global');
      // open menu sp
      for (const button of this.elm) {
        button.addEventListener('click', () => {
          menuSp.classList.add(isShow);
          $('html').addClass('is-locked');
        });
      }

      // close menu sp
      for (const buttonClose of this.btnClose) {
        buttonClose.addEventListener('click', () => {
          menuSp.classList.remove(isShow);
          $('html').removeClass('is-locked');
        });
      }
    }
  }
}