import { isAnimate, isActive, isHide, breakpointMD } from '../_variables';
import $ from 'jquery';
import bodymovin from 'lottie-web';
import slick from 'slick-carousel';

export default class Top {
  constructor(props) {
    this.elm = document.querySelector(props.target);
    this.animated = document.querySelectorAll('.js-content-animated');
  }

  removeLandingPage() {
    const landingElmt = document.querySelector('.section-loading');
    const contentTop = document.querySelector('.l-contents-top');
    landingElmt.classList.add(isHide);

    setTimeout(() => {
      landingElmt.style.display = 'none';
      contentTop.style.visibility='visible';
    }, 400);
  }

  personSlider () {
    const $personSlider = $('.p-person__list');

    $personSlider.slick({
      slidesToShow: 1,
      centerMode: true,
      centerPadding: '0px',
      dotsClass: 'slick-dots',
      dots: true,
      arrows: true,
      prevArrow: '<div class="p-person__arrow slider_prev"><span></span></div>',
      nextArrow: '<div class="p-person__arrow slider_next"><span></span></div>',
      speed: 1000,
      autoplay: true,
      autoplaySpeed: 8000,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,
      draggable: false,
      swipe: false,
      infinite: true,
      rtl: true,
      customPaging: function (slider, i) {
        return '<a class="dot" data-slider-dot="' + i + '"></a>';
      },
      responsive: [
        {
          breakpoint: 897,
          settings: {
            centerPadding: '30px'
          }
        }
      ]
    // }).on('beforeChange', (event, slick, currentSlide) => {
    //   $('.p-person-thumb__list').slick('slickGoTo', currentSlide - 1);
    });
  }

  thumbSlider () {
    const $thumbSlider = $('.p-person-thumb__list');
    const prev = document.querySelector('.p-person__arrow.slider_prev');
    const next = document.querySelector('.p-person__arrow.slider_next');
    const dott = document.querySelectorAll('.slick-dots .dot');

    prev.addEventListener('click', (e) => {
      $('.p-person-thumb__list').slick('slickPrev');
      $('.p-person-thumb__list').addClass('slider-click');
    });

    next.addEventListener('click', (e) => {
      $('.p-person-thumb__list').slick('slickNext');
      $('.p-person-thumb__list').addClass('slider-click');
    });


    for (const item of dott) {
      item.addEventListener('click', (e) => {
        let currentSide = item.getAttribute('data-slider-dot');
        $thumbSlider.slick('slickGoTo', currentSide);
      });
    }

    $thumbSlider.slick({
      slidesToShow: 5,
      slidesToScroll: 1,
      speed: 1000,
      autoplay: true,
      autoplaySpeed: 8000,
      infinite: true,
      arrows: false,
      dots: false,
      focusOnSelect: true,
      rtl: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      pauseOnDotsHover: false,
      draggable: false,
      swipe: false,
      asNavFor: '.p-person__list',
      responsive: [
        {
          breakpoint: 1919,
          settings: {
            slidesToShow: 4,
          }
        }
      ]
    // }).on('beforeChange', (event, slick, currentSlide) => {
      // $('.p-person__list').slick('slickNext');
    });
  }

  headerTop () {
    const scrollY = window.pageYOffset;
    const headerTop = document.querySelector('.c-header');
    const logoImage = document.getElementById('logo').src;
    if (scrollY >= 10 ) {
      headerTop.classList.add(isActive);
      document.getElementById('logo').src = '/assets/image/common/logo.png';
    } else {
      headerTop.classList.remove(isActive);
      document.getElementById('logo').src = '/assets/image/common/logo_white.png';
    }
  }

  mvTop () {
    const $mvTop = $('.p-top-main__list');
    $mvTop.slick({
      arrows: false,
      autoplay: true,
      autoplaySpeed: 7000,
      speed: 2000,
      pauseOnHover: false,
      fade: true,
      infinite: true,
      swipe: false,
      useCSS: false
    }).on('afterChange', function( e, slick, currentSlide ) {
      if( currentSlide == 0 ) {
        $mvTop.slick('slickPause');
      }
    }).on('beforeChange', function( e, slick, currentSlide ) {
      if( currentSlide == 4 ) {
        $('.p-top-main__image--pc').addClass('is-end');
        $('.p-top-main__image--sp').addClass('is-end');
      }
    });
  }

  scrollAnimate () {
    let winTop = window.pageYOffset + (window.innerHeight / 1);
    for (const box of this.animated) {
      const boxTop = $(box).offset().top;
      if (winTop >= boxTop) {
        if (!box.classList.contains(isAnimate)) {
          box.classList.add(isAnimate);
        }
      }
    }
  }

  textHero () {
    setTimeout(() => {
      const animation_sp = bodymovin.loadAnimation({
        container: document.getElementById('hero-sp'),
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path: '/assets/file/txt_hero_sp.json'
      });

      const animation = bodymovin.loadAnimation({
        container: document.getElementById('hero'),
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path: '/assets/file/txt_hero.json'
      });
    }, 3000);
  }

  iconLoading () {
    const loading = bodymovin.loadAnimation({
      container: document.getElementById('loading'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/assets/file/loading.json'
    });
  }

  googleMap () {
    const script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBX9TXKNSKel_KoXVGmeAi17nHl2qMTlTo&callback=initMap';
    script.defer = true;

    window.initMap = function() {
      const latLngCenter = {lat: 34.043687, lng: 131.595223};
      const latLngMarker = {lat: 34.041677, lng: 131.584012};
      const latLngMap = window.innerWidth < breakpointMD ? latLngMarker : latLngCenter;
      let map = new google.maps.Map(document.getElementById('map'), {
        center: latLngMap,
        zoom: 15,
        styles: [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "color": "#f5f5f5"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#bdbdbd"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#ffffff"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#757575"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#dadada"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#616161"
              }
            ]
          },
          {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#e5e5e5"
              }
            ]
          },
          {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#eeeeee"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#c9c9c9"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#9e9e9e"
              }
            ]
          }
        ]
      });

      const marker = new google.maps.Marker({
        position: latLngMarker,
        map: map,
        icon: '/assets/image/top/pin.png'
      });
    };

    document.head.appendChild(script);
  }

  init() {
    if (this.elm) {
      this.mvTop();
      this.textHero();
      this.iconLoading();
      this.personSlider();
      this.thumbSlider();
      this.headerTop();
      this.googleMap();

      window.addEventListener('scroll', () => {
        this.scrollAnimate();
        this.headerTop();
      });

      document.addEventListener('readystatechange', event => {
        // When HTML/DOM elements are ready:
        if (event.target.readyState === "loading") {
          document.querySelector('.l-contents-top').style.visibility='hidden';
        }
        // When window loaded ( external resources are loaded too- `css`,`src`, etc...)
        if (event.target.readyState === "complete") {
          this.removeLandingPage();
        }
    });
    }
  }
}
