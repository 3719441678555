import { breakpointMD } from '../_variables';
import $ from 'jquery';
import slick from 'slick-carousel';

export default class SliderState {
  constructor(props) {
    this.elm = document.querySelector(props.target);
  }

  sliderstate () {
    const sliderstate = $('.js-slider-state');
    sliderstate.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 1000,
      infinite: true,
      arrows: true,
      dots: false,
      responsive: [
        {
          breakpoint: 897,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '30px',
            arrows: false,
            dots: true,
            centerMode: true
          }
        }
      ]
    });
  }

  init() {
    if (this.elm) {
      this.sliderstate();
    }
  }
}