import 'babel-polyfill';
import '@webcomponents/webcomponentsjs';
import Pagetop from './modules/pagetop';
import Top from './modules/top';
import Gnavisp from './modules/gnavisp';
import AnchorLink from './modules/anchorLink';
import SliderSp from './modules/slidersp';
import SliderState from './modules/sliderstate';
import Tab from './modules/tab';

const pagetop = new Pagetop({
  target: '.c-footer__btn'
});

const top = new Top({
  target: '#top'
});

const gnavisp = new Gnavisp ({
  target: '.js-open-menu'
});

const anchorLink = new AnchorLink({
  target: '.js-anchor'
});

const slidersp = new SliderSp({
  target: '.js-slider-sp'
});

const sliderstate = new SliderState({
  target: '.js-slider-state'
});

const tab = new Tab({
  target: '.js-content-tab'
});

top.init();
gnavisp.init();
pagetop.init();
anchorLink.init();
slidersp.init();
sliderstate.init();
tab.init();