import $ from 'jquery';

export default class AnchorLink {
  constructor(props) {
    this.anchors = document.querySelectorAll(props.target);
  }

  init() {
    if (this.anchors.length) {
      const header = document.querySelector('.c-header');
      for (let i = 0; i < this.anchors.length; i++) {
        this.anchors[i].addEventListener('click', (e) => {
          e.preventDefault();
          const self = event.currentTarget;
          const href = self.getAttribute('href');
          const headerHeight = header.offsetHeight;
          const posTarget = $(href).offset().top - (headerHeight - 1);
          $('body, html').stop().animate({
            scrollTop: posTarget
          }, 400, 'swing');
        });
      }
    }
  }
}